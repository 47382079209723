<template>
  <div class="set-recipe-modal default-modal">
    <div class="default-modal__header">
      <h2>Рецепт</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="default-modal__body">
      <EditorJSComponent :text="recipe" />
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "SetRecipeModal",
  props: {
    recipe: String,
  },
  components: { EditorJSComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.set-recipe-modal {
  width 100%
  max-width 1100px
  border-radius: 16px;
  padding 32px 32px
}
</style>
