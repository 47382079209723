<template>
  <main class="sets-open-page page">
    <div class="page__inner">
      <SectionComponent>
        <SetsOpenDescription :data="data" />
        <div class="sets-open-page__wrapper">
          <h2>Что входит в набор</h2>
          <div class="sets-open-page__grid">
            <CardComponent v-for="(item, i) in data.products" :key="i" :data="item" />
          </div>
        </div>
      </SectionComponent>
    </div>
  </main>
</template>

<script>
import SetsOpenDescription from "./components/SetsOpenDescription.vue";
import SectionComponent from "components/SectionComponent.vue";
import CardComponent from "components/CardComponent.vue";
import GET from "@/graphql/pages/store/SetItemPage.graphql";
export default {
  name: "SetOpenPage",
  components: { CardComponent, SectionComponent, SetsOpenDescription },
  async asyncData({ apollo, store, route }) {
    let token = store.state.auth_token;
    await apollo.clients.store
      .query({
        query: GET,
        variables: {
          id: parseInt(route.params.id),
        },
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        store.state.set_item_page = data.product_sets_item;
      });
  },
  computed: {
    data() {
      return this.$store.state.set_item_page;
    },
  },
};
</script>

<style lang="stylus">
.sets-open-page{
  &__grid{
    display grid
    grid-template-columns repeat(5, 1fr)
    gap 24px

    +below(1024px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(860px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(628px) {
      gap 16px
    }
    +below(328px) {
      grid-template-columns repeat(1, 1fr)
    }
  }

  &__wrapper {
    display flex
    flex-direction column
    gap 24px

    & h2 {
      margin: 0
    }
  }
}
</style>
