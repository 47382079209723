<template>
  <div class="sets-description">
    <template v-if="data.video_link">
      <template v-if="!startVideo">
        <div class="sets-description__preview">
          <ImgComponent :img="data.img" />
          <div class="sets-description__preview-actions">
            <div @click="startVideo = true" class="sets-description__play">
              <IconComponent name="media-play" />
            </div>
            <span>Видео-рецепт</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="sets-description__video">
          <IframeComponent :link="data.video_link" class="sets-description__frame" />
        </div>
      </template>
    </template>
    <div v-else class="sets-description__img">
      <ImgComponent :img="data.img" />
    </div>
    <div class="sets-description__text">
      <div class="sets-description__header">
        <div class="sets-description__header-title">
          <h3>{{ data.title }}</h3>
          <div class="sets-description__header-rating">
            <!--            <span class="sets-description__rating">-->
            <!--              <RemixIconComponent category="system" name="star-fill" />-->
            <!--              4.7-->
            <!--            </span>-->
            <span class="sets-description__quantity">{{
              data.products_count | plural("товар", "товара", "товаров")
            }}</span>
          </div>
        </div>
        <!--        <div class="sets-description__buttons">-->
        <!--          <button class="btn btn&#45;&#45;share btn&#45;&#45;md">-->
        <!--            <RemixIconComponent category="system" name="share-line" />-->
        <!--            Поделиться-->
        <!--          </button>-->
        <!--          <button class="btn btn&#45;&#45;white btn&#45;&#45;circle">-->
        <!--            <RemixIconComponent category="health" name="heart-line" />-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
      <div class="sets-description__main">
        <div class="sets-description__main--left">
          <span>
            {{ data.description }}
          </span>
          <h6>На 100 граммов</h6>
          <div class="sets-description__main-indicators">
            <div v-for="(item, i) in indicators" :key="i">
              <span>{{ item.title }}</span>
              <p>{{ item.subtitle }}</p>
            </div>
          </div>
        </div>
        <!--        <div class="sets-description__main&#45;&#45;right">-->
        <!--          <SetsCategoryTabsComponent :data="sets" />-->
        <!--        </div>-->
      </div>
      <div class="sets-description__footer">
        <button v-if="data.recipe" @click="openRecipeModal" class="btn btn--main-hollow btn--md">
          Читать рецепт
          <RemixIconComponent category="system" name="arrow-right-line" />
        </button>
        <div class="sets-description__footer--right">
          <div v-if="setPriceWithSale !== setPriceWithoutSale" class="sets-description__footer-price">
            <span class="sets-description__default-price">{{ setPriceWithoutSale | fixedTwo }} ₽</span>
            <span class="sets-description__sale-price">{{ setPriceWithSale | fixedTwo }} ₽</span>
          </div>
          <span v-else class="sets-description__price">{{ setPriceWithoutSale | fixedTwo }} ₽</span>
          <!--          <span class="sets-description__footer_scores">27 баллов</span>-->
          <ProductCountChangeComponent :data="data" is-set />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import SetsCategoryTabsComponent from "./SetsCategoryTabsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
import IconComponent from "components/IconComponent.vue";
import IframeComponent from "components/IframeComponent.vue";
import SetRecipeModal from "../modals/SetRecipeModal.vue";
export default {
  name: "SetsOpenDescription",
  props: {
    data: Object,
  },
  data() {
    return {
      startVideo: false,
      indicators: [
        { title: this.data.calories || "-", subtitle: "ккал" },
        { title: this.data.proteins || "-", subtitle: "белки" },
        { title: this.data.fats || "-", subtitle: "жиры" },
        { title: this.data.carbohydrates || "-", subtitle: "углеводы" },
      ],
    };
  },
  methods: {
    openRecipeModal() {
      this.$store.state._modals.push({
        component: SetRecipeModal,
        options: { recipe: this.data.recipe },
      });
    },
  },
  computed: {
    productsWithPrice() {
      return this.data.products.filter((item) => item.price);
    },
    setPriceWithoutSale() {
      return this.productsWithPrice.reduce((acc, item) => {
        let productPrice = item.price?.price_without_discount
          ? item.price?.price_without_discount
          : item.price?.value;
        return productPrice + acc;
      }, 0);
    },
    setPriceWithSale() {
      return this.productsWithPrice.reduce((acc, item) => item.price?.value + acc, 0);
    },
  },
  components: {
    IframeComponent,
    IconComponent,
    ProductCountChangeComponent,
    ImgComponent,
    SetsCategoryTabsComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.sets-description{
  display grid
  grid-template-columns 1fr 1fr
  gap 24px

  +below(880px) {
    //grid-template-columns 1fr
    display flex
    flex-direction column
  }

  &__preview{
    border-radius: 32px;
    max-height: 505px;
    position: relative;
    display flex
    justify-content center
    align-items center

    +below(880px) {
      min-height 320px
    }

    +below(428px) {
      min-height 250px
    }

    &::before {
      content ''
      position absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      opacity: 0.7;
      background: #000;
      border-radius: 32px;
      z-index 1
    }

    .img{
      border-radius: 32px;
      position: absolute
      right: 0;
      left 0
      height 100%
    }

    &-actions {
      display flex
      flex-direction column
      gap 16px
      justify-content center
      align-items center
      color: var(--theme-white, #FFF);
      text-align: center;
      font-size: 1.25rem
      font-weight: 400;
      line-height: 140%; /* 28px */
      z-index 2

      +below(428px) {
        font-size: 1rem
      }
    }
  }

  &__video {
    border-radius: 32px;
    max-height 505px
    +below(880px) {
      height 320px
    }
    +below(428px) {
      height 250px
    }
  }

  &__frame {
    width 100%
    height 100%
    border-radius 32px
    overflow hidden
  }

  &__play {
    cursor: pointer;
    padding: 16px
    display flex
    justify-content center
    align-items center
    border-radius: 99px;
    background: var(--theme-primary, #5AB033);

    +below(428px) {
      padding: 12px
    }

    & .icon {
      width 24px
      height 24px
    }
  }

  &__text{
    display flex
    flex-direction column
    gap 20px
  }
  &__header{
    display flex
    justify-content space-between
    width: 100%;

    +below(1024px) {
      flex-direction column
      gap 10px
    }

    &-title{
      display flex
      flex-direction column
      gap 4px
      h3{
        margin 0
      }
    }
    &-rating{
      display flex
      align-items center
      gap 16px
    }
  }

  &__quantity {
    color: var(--theme-body-muted, #919191);
    font-size: 0.75rem
    font-weight: 400;
    line-height: normal;
  }

  &__rating {
    display flex
    align-items center
    gap 4px
    font-weight: 600;
    font-size: 0.75em;
    line-height: 16px;
    color: var(--gray-900);
    background-color var(--gray-100)
    border-radius: 4px;
    width: fit-content;
    padding 4px 8px
    svg{
      width: 15px;
      height: 15px;
      path{
        fill var(--yelow-500)
      }
    }
  }

  &__buttons{
    display flex
    gap 16px
    align-items center
  }

  &__main{
    display grid
    grid-template-columns 1fr 1fr
    gap 24px

    +below(1024px) {
      grid-template-columns 1fr
      gap 10px
    }

    &--left{
      display flex
      flex-direction column
      gap 16px
      p{
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: var(--gray-900);
      }
      h6{
        text-transform  none
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: var(--gray-900);
        margin 0
      }
    }
    &-indicators{
      display grid
      grid-template-columns 1fr 1fr
      gap 16px
      div{
        display flex
        flex-direction column
        padding 16px
        background: var(--gray-100);
        border-radius: 16px;
      }

      span{
        font-weight: 500;
        font-size: 1em;
        line-height: 140%;
        color: var(--gray-900);
      }

      p{
        font-weight: 400;
        font-size: 0.875em;
        line-height: 140%;
        color: var(--body_muted);
      }
    }
  }

  &__footer{
    display grid
    grid-template-columns 1fr 1fr
    gap 24px

    +below(480px) {
      grid-template-columns 1fr
      gap 16px
    }

    &--right{
      display flex
      justify-content space-between
      align-items: center;
    }
    .btn{
      border-radius: 16px;
      height 46px
      min-width 46px

      & .icon {
        width 20px
        height 20px
      }
    }

    &-price {
      display flex
      flex-direction column
    }

    &_scores{
      background-color var(--yelow-100)
      font-weight: 400
      display flex
      gap 4px
      padding 5px 8px
      height: fit-content;
      align-items center
      border-radius 4px
      font-size: 0.875em;
      line-height: 19px;
      color: var(--gray-900);
    }
  }
  &__default-price {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 16px;
    text-decoration-line: line-through;
    color: var(--gray-400);
    margin 0
  }

  &__sale-price {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 140%;
    color: var(--red);
  }

  &__price {
    font-size: 1.25rem
    font-weight: 400;
    line-height: 140%; /* 28px */
  }

  &__img {
    width 100%
    border-radius: 32px;
    max-height: 505px;
    overflow hidden
  }
}
</style>
