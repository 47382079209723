<template>
  <div class="iframe" v-if="isIframe" v-html="link"></div>
  <div class="iframe" v-else-if="link">
    <iframe
      width="1046"
      height="490"
      :src="embedLink"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "IframeComponent",
  props: {
    link: String,
  },
  computed: {
    isIframe() {
      return this.link.includes("<iframe");
    },
    embedLink() {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      let match = this.link.match(regExp);
      if (match && match[2].length == 11) {
        return "https://www.youtube.com/embed/" + match[2];
      }
      return undefined;
    }
  },
};
</script>

<style lang="stylus">
.iframe {
  width: 100%;
  height: 500px;

  > iframe {
    width: 100%
    height: 100%
  }
}
</style>
